<template>
<!-- 公共上传文件组件 -->
<!-- uploadFileUrl是在src/config/index.js配置的文件上传路径 -->
<div class="drag-upload-comp">
  <el-upload
    :ref="ref"
    :id="ref"
    v-if="!fileList || fileList.length < limit"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="img-uploader"
    drag
    :multiple="multiple"
    :with-credentials="false"
    :action="uploadFileUrl"
    :name="name"
    :disabled="disabled"
    :limit="limit"
    :show-file-list="showFileList"
    :file-list="fileList"
    :on-exceed="onExceed"
    :on-success="onSuccess"
    :on-error="onError"
    :before-upload="beforeUpload"
    :on-remove="onRemove"
    :headers="headers"
    :on-change="onChange">
    <slot>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        <p>将文件拖到此处，或<em>点击上传</em>，最多仅支持上传20张图片</p>
        <p>支持扩展名：JPG、JPEG、PNG</p>
      </div>
    </slot>

  </el-upload>
</div>
</template>

<script>
import { getToken } from '@/utils/localStorage.js'

export default {
  name: 'dragUpload',
  props: {
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    limit: {
      type: Number,
      default: 1
    },
    // 当文件为多选时，每次选择图片的上限，跟上次图片的上限limit不一样，limit是现在总的上传数量，selectLimit为单次选择图片的数量，默认-1，表示无限制
    selectLimit: {
      type: Number,
      default: -1
    },
    // 是否显示已上传文件列表
    showFileList: {
      type: Boolean,
      default: true
    },
    // 是否支持多选文件
    multiple: {
      type: Boolean,
      default: true
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 文件对应字段，看接口要求
    name: {
      type: String,
      default: 'multipartFile'
    },
    // 上传接口需要传入的businessType字段：业务类型 order=订单，company=公司文件，user=用户文件
    businessType: {
      type: String,
      default: 'company'
    },
    // 文件格式不匹配的错误提示文本
    typeErrorMsg: {
      type: String,
      default: '请按要求的格式上传文件'
    },
    // 文件大小限制，k为单位，默认限制1M以内，即1024k
    sizeLimit: {
      type: Number,
      default: 1024
    },
    // 用正则来校验fileName的后缀名，来校验文件类型，考虑Mac上的兼容性，通过fileName来判断文件类型，默认支持图片类型
    acceptTypes: {
      type: RegExp,
      default: () => {
        return /.jpg$|.jpeg$|.png$|.bmp$|.JPG$|.JPEG$|.PNG$|.BMP$/
      }
    },
    headers: {
      type: Object,
      default: () => {
        return { 'dreamkey-token': getToken() }
      }
    }
  },
  data() {
    return {
      loading: false,
      ref: 'dragUpload_' + Math.floor(Math.random() * 10000),
      abortNum: 0
    }
  },
  created() {
  },
  mounted() {
    // const dragDiv = document.getElementById(this.ref).getElementsByClassName('el-upload-dragger')
    // console.log('dragDiv')
    // console.log(dragDiv)
    // dragDiv[0].addEventListener('drop', this.onDrop)
  },
  computed: {},
  watch: {
    params: {
      handler: function (val) {
        this.getList()
      },
      deep: true
    }
  },
  methods: {
    onDrop(e) {

      // if (this.selectLimit === -1) return
      // const selectedLen = e.dataTransfer.files.length
      // if (selectedLen > this.selectLimit) {
      //   e.dataTransfer.files.forEach(file => {
      //     this.$refs[this.ref].abort(file)
      //   })
      //   this.$message.error(`最大一次拖动或选择${this.selectLimit}张图片,本次选择${selectedLen}张,请重新选择`)

      //   this.loading = false
      // }
    },
    /** 文件上传成功 */
    onSuccess(res, file) {
      this.loading = false
      if (res.code === 0) {
        this.$emit('onSuccess', { res, file })
      } else {
        this.$message.error(res.msg || '上传失败')
      }
    },
    onError(err, file, fileList) {
      console.error(err)
      this.loading = false
    },
    onRemove(file, fileList) {
      this.$emit('onRemove', { file, fileList })
    },
    onExceed() {
      this.$message.warning(`限制上传${this.limit}个文件,如果要重新上传，请先删除已选记录`)
      this.$emit('onExceed')
    },
    onChange(file, fileList) {
      // const selectedLen = this.$refs[this.ref].$refs['upload-inner'].$refs.input.files.length
      // if (this.selectLimit !== -1 && selectedLen > this.selectLimit) {
      //   this.$message.error(`最大一次拖动或选择${this.selectLimit}张图片,本次选择${selectedLen}张,请重新选择`)
      //   this.abortNum = selectedLen
      // }

      this.$emit('onChange', { file, fileList })
    },
    /**
     * 上传之前的文件检查
     * */
    beforeUpload(file) {
      const acceptTypes = this.acceptTypes
      const isLimit = file.size / 1024 < this.sizeLimit
      const fileName = file.name

      // 如果超出每次选择文件的限制数量则直接取消上传
      // if (this.abortNum > 0) {
      //   this.abortNum = this.abortNum - 1
      //   return false
      // }
      if (!fileName || !acceptTypes.test(fileName)) {
        this.$message.error(this.typeErrorMsg)
        return false
      }
      if (!isLimit) {
        this.$message.error(`上传图片大小不能超过 ${(this.sizeLimit / 1024).toFixed(2)}MB!`)
      } else {
        this.loading = true
      }

      return isLimit
    },
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    }

  }
}
</script>

<style lang="scss">
  .drag-upload-comp{
    &>div+div{
      margin-left: 16px;
    }
     .el-upload-list{
        vertical-align: top;
      }
      // .img-uploader__slot{
      //   padding-left: 20px;
      // }
  }
</style>
