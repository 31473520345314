<template>
  <div class="authenticity-summary">
    <!-- 确真 -->
    <div class="ym-panel-radius">
      <div class="title">
        <span>确真综合结果</span>
        <el-tooltip effect="light" placement="top" content="两个鉴定机构只要有一个机构鉴定结果为仿品则确真综合结果为仿品" popper-class="result-tooltip">
          <i class="tips el-icon-question"></i>
        </el-tooltip>
        <div class="result fake" v-if="handleFinalResult === '仿品'">仿品</div>
        <div class="result" v-if="handleFinalResult === '真品'">真品</div>
      </div>
      <template v-for="dept in dataInfo.identifyRealVos">
        <div class="dept" :key="dept.deptId">
          <div class="dept-header">{{dept.deptName}}</div>
          <div class="dept-result">
            <div class="block-left">
              <el-row class="row-item">
                <el-col>
                  <div class="label">鉴定项目：</div>
                  <div class="content options">
                    <template v-for="option in dept.collectionDetectVos">
                      <el-tag type="info" :key="option.detectId" class="option">{{option.detectItemName}}</el-tag>
                    </template>
                  </div>
                </el-col>
              </el-row>
              <el-row class="row-item">
                <el-col>
                  <div class="label">确真结果：</div>
                  <template v-if="!dept.identifyResult">
                    <div class="content">
                      <!-- 根据登录用户的所属机构判断是否存在去提交按钮，管理员和运营存在按钮 -->
                      <span class="tips-warning">(待提交)</span>
                      <el-button type="primary" size="small" @click="goSubmit('authenticity')" v-if="hasPermission || (userInfo && (userInfo.deptId === dept.deptId))">去提交</el-button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="result fake" v-if="dept.identifyResult === '仿品'">仿品</div>
                    <div class="result" v-else>真品</div>
                  </template>
                </el-col>
              </el-row>
              <el-row class="row-item">
                <el-col class="conclusion">
                  <div class="label">鉴定结论：</div>
                  <div class="content" v-html="showParagraph(dept.conclusion ? dept.conclusion : '无')"></div>
                </el-col>
              </el-row>
            </div>
            <div class="block-right">
              <div class="operator">
                <el-button class="btn" :disabled="!dept.identifyResult" @click="viewReport(dept.deptId)">总鉴定报告</el-button>
                <el-button class="btn" :disabled="!dept.identifyResult" v-for="(option, index) in dept.collectionDetectVos" :key="option.detectId" @click="viewTestReport(dept.deptId, option.detectId)">
                  <span class="ellipsis" :title="option.detectItemName">检测项目报告{{index + 1}}</span>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { showParagraph } from '@/utils/handleParagraph.js'

export default {
  props: {
    submitted: {
      type: Boolean,
      default: false
    },
    dataInfo: {
      type: Object,
      default: () => {}
    },
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    hasPermission() {
      return this.userInfo.permissionsTags.includes('admin')
    },
    // 统计所有机构的检测结果
    handleFinalResult() {
      console.log(this.dataInfo.identifyRealVos)
      if (this.dataInfo.identifyRealVos) {
        let count = 0
        let fakeMark = false
        for (let i = 0; i < this.dataInfo.identifyRealVos.length; i++) {
          if (this.dataInfo.identifyRealVos[i].identifyResult === '仿品') {
            fakeMark = true
            break
          } else if (this.dataInfo.identifyRealVos[i].identifyResult === '真品') {
            count++
          }
        }
        if (fakeMark) {
          return '仿品'
        } else if (count === this.dataInfo.identifyRealVos.length) {
          return '真品'
        } else {
          return ''
        }
      }
      return ''
    }
  },
  methods: {
    showParagraph,
    viewReport(deptId) {
      const deptInfo = this.dataInfo.identifyRealVos.find(dept => dept.deptId === deptId)
      if (deptInfo && deptInfo.file) {
        const url = this.FileUrl + deptInfo.file
        window.open(url, 'blank')
      }
    },
    // 查看指定机构的指定项目检测报告
    viewTestReport(deptId, detectId) {
      const dept = this.dataInfo.identifyRealVos.find(dept => dept.deptId === deptId)
      if (dept && dept.collectionDetectVos) {
        const url = this.FileUrl + dept.collectionDetectVos.find(option => option.detectId === detectId).testReport
        window.open(url, 'blank')
      }
    },
    goSubmit(flag) {
      this.$emit('goSubmit', flag)
    }
  }
}
</script>

<style lang="scss">
.result-tooltip {
  color: $--color-text-primary;
}
</style>

<style lang="scss" scoped>
.authenticity-summary {
  color: $--color-text-primary;
  .result {
    width: 36px;
    height: 20px;
    text-align: center;
    font-size: 12px;
    color: #4DB355;
    padding: 0 4px;
    border: 1px dashed #4DB355;
    background-color: #F2FFF6;
    border-radius: 2px;
    line-height: 20px;
    &.fake {
      color: #FA1F21;
      background-color: #FEDADA;
      border-color: #FA1F21;
    }
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 2px 0 24px;
    span {
      margin-right: 8px;
      font-weight: 700;
    }
    .tips {
      margin-right: 8px;
      font-size: 16px;
      color: $--color-text-secondary;
    }
  }
  .dept {
    border: 1px solid #EBEDF0;
    & + .dept {
      margin-top: 24px;
    }
    .dept-header {
      height: 56px;
      line-height: 56px;
      padding: 0 25px;
      font-size: 16px;
      font-weight: 600;
      background-color: #F7F8FA;
    }
    .dept-result {
      display: flex;
      padding: 20px 0px;
      .block-left {
        width: 81.5%;
        padding: 0 25px;
        .row-item {
          font-size: 14px;
          margin-bottom: 12px;
          .el-col {
            display: flex;
            align-items: center;
            &.conclusion {
              align-items: flex-start;
              .content {
                text-align: justify;
              }
            }
          }
          .label {
            width: 70px;
            min-width: 70px;
            color: $--color-text-secondary;
          }
          .content {
            .el-button {
              width: 60px;
              height: 24px;
              padding: 0;
              margin-left: 12px;
              text-align: center;
              line-height: 24px;
            }
            .tips-warning {
              color: $--color-warning;
            }
          }
          .options {
            display: flex;
            flex-wrap: wrap;
            .option {
              height: 20px;
              line-height: 20px;
              margin: 2px 12px 2px 0;
              padding: 0 8px;
              color: #5C6578;
              background-color: #F0F1F3;
              border-color: #F0F1F3;
              font-size: 12px;
            }
          }
        }
      }
      .block-right {
        display: flex;
        width: calc(100% - 81.5%);
        justify-content: center;
        align-items: center;
        border-left: 1px solid $--border-color-base;
        min-width: 280px;
        .operator {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .btn {
            margin: 0 auto;
            width: 130px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            padding: 0 6px;
            font-size: 14px;
            span {
              display: inline-block;
              width: 100%;
            }
            & + .btn {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
