<template>
  <div class="department">
    <el-card>
      <div class="ym-head clearfix">
        <div class="ym-title-border">组织架构信息</div>
      </div>
      <div class="main">
        <!-- 侧边栏 -->
        <div class="side-bar">
          <div class="search-bar">
            <el-input v-model="search" placeholder="搜索成员" prefix-icon="el-icon-search" size="small" clearable @change="handleSearch"></el-input>
          </div>
          <div class="dept-tree">
            <el-tree ref="treeRef"
                     :data="deptList"
                     node-key="deptId"
                     :props="defaultProps"
                     :current-node-key="currentNodeKey"
                     :expand-on-click-node="false"
                     highlight-current
                     :default-expand-all="true"
                     @node-click="handleNodeClick">
              <div class="dept-tree-node" slot-scope="{ node, data }">
                <p class="dept-tree-node-label ellipsis" :title="node.label">{{ node.label }}</p>
                <!-- <div class="dept-tree-node-icon" @click="handleIconClick(data)">
                  <i class="el-icon-more"></i>
                </div> -->
                <div @click.stop="handleIconClick(data)">
                  <el-dropdown trigger="click" @command="handleOptionClick" class="dept-dropdown">
                    <div class="dept-tree-node-icon">
                      <i class="el-icon-more"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="edit" v-if="data.deptId !== '1'">编辑机构</el-dropdown-item>
                      <el-dropdown-item command="add">添加子机构</el-dropdown-item>
                      <el-dropdown-item command="delete" class="dropdown-delete" v-if="data.deptId !== '1'">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </el-tree>
          </div>
        </div>
        <div class="content">
          <div class="header">
            <div class="left">
              <i class="ym-icon-group-fill"></i>
              <span class="count">{{pageData.total}}</span>
            </div>
            <div class="right">
              <el-button size="small" type="primary" @click="handleAdd">添加成员</el-button>
            </div>
          </div>
          <ym-table-list :loading="listLoading"
                         :data="listData"
                         :columns="listColumns"
                         :pageData="pageData"
                         @handlePageChange="handlePageChange">

            <template #userName="{row}">
              {{row.userName ? row.userName : '-'}}
            </template>
            <template #mobilePhone="{row}">
              {{row.mobilePhone ? row.mobilePhone : '-'}}
            </template>
            <template #userEmail="{row}">
              {{row.userEmail ? row.userEmail : '-'}}
            </template>
            <template #groupNameList="{row}">
              {{row.groupNameList.join(',') }}
            </template>
          </ym-table-list>
        </div>
      </div>
    </el-card>
    <el-dialog :visible.sync="dialogVisible"
               :title="dialogTitle"
               top="120px"
               width="500px"
               :close-on-click-modal="false">
      <el-form ref="deptForm"
               :model="deptForm"
               :rules="rules"
               label-width="125px"
               v-loading="detailLoading"
               v-if="dialogVisible">
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="deptName" label="机构名称">
              <el-input v-model="deptForm.deptName" size="small" placeholder="请输入" :disabled="deptOperator !== 'add'" maxlength="20"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="deptType" label="机构类型">
              <el-select v-model="deptForm.deptType" placeholder="请选择" :disabled="deptOperator !== 'add'" class="select-dept-type" @change="handleDeptTypeChange">
                <el-option v-for="department in departmentTypeList"
                          :key="department"
                          :label="department"
                          :value="department"></el-option>
                <!-- <el-option label="分中心" value="分中心"></el-option>
                <el-option label="鉴定机构" value="鉴定机构"></el-option>
                <el-option label="机构子部门(档案订单归属排除)" value="子部门"></el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16" v-if="deptForm.deptType === '鉴定机构'">
          <el-col :span="22">
            <el-form-item prop="verifyType" label="鉴定资格">
              <el-select v-model="deptForm.verifyType" placeholder="请选择" class="select-dept-type" :disabled="deptOperator !== 'add'">
                <el-option v-for="type in identifyTypeList"
                          :key="type"
                          :label="type"
                          :value="type"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16" v-if="deptForm.verifyType === '终鉴-确真'">
          <el-col :span="22">
            <el-form-item prop="identifyType" label="鉴定项目">
              <el-select v-model="deptForm.detectItemIds" placeholder="请选择" multiple popper-class="identify-type-popper" class="identify-type-select">
                <el-option-group v-for="group in identifyList"
                                 :key="group.detectMethod"
                                 :label="group.detectMethod">
                  <el-option v-for="item in group.options"
                            :key="item.detectItemId"
                            :label="item.detectItemName"
                            :value="item.detectItemId"></el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="userId" label="机构领导">
              <el-select v-model="deptForm.userId" placeholder="请选择" clearable filterable class="select-leader">
                <el-option v-for="user in userList"
                          :key="user.userId"
                          :label="user.userName ? `${user.userName} (${user.loginAccount})` : user.loginAccount"
                          :value="user.userId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="company" label="运营公司名称">
              <el-input v-model="deptForm.company" maxlength="50" :title="deptForm.company" size="small" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="license" label="公司营业执照编号">
              <el-input v-model="deptForm.license" maxlength="30" :title="deptForm.license" size="small" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="contactPerson" label="公司联系人姓名">
              <el-input v-model="deptForm.contactPerson" maxlength="40" size="small" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="email" label="联系邮箱">
              <el-input v-model="deptForm.email" maxlength="100" size="small" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="phone" label="联系电话">
              <el-input v-model="deptForm.phone" maxlength="20" size="small" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkDeptForm">确 定</el-button>
      </span>
    </el-dialog>
    <add-user-dialog :addUserDialogVisible="addUserDialogVisible"
                     :deptList="deptList"
                     @handleUserDialogClose="handleUserDialogClose('add')"
                     @showAddedMemberDepartment="setCurrentKey"
                     @getUserList="getUserList"
                     v-if="addUserDialogVisible"></add-user-dialog>
    <edit-user-dialog :editUserDialogVisible="editUserDialogVisible"
                      :userData="userData"
                      @handleUserDialogClose="handleUserDialogClose('edit')"
                      @onSuccess="handleEditSuccess"
                      v-if="editUserDialogVisible"></edit-user-dialog>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import AddUserDialog from './addUserDialog/index.vue'
import EditUserDialog from '@/views/application/user/editUserDialog/index.vue'
import { getUserInfo } from '@/utils/localStorage.js'

export default {
  components: {
    YmTableList,
    AddUserDialog,
    EditUserDialog
  },
  data() {
    let checkVerifyType = (rules, value, callback) => {
      if (this.deptForm.deptType !== '鉴定机构') {
        callback()
      } else if (!value) {
        callback(new Error('请选择鉴定资格'))
      } else {
        callback()
      }
    }

    return {
      listLoading: false,
      detailLoading: false,
      search: '',
      defaultProps: {
        children: 'children',
        label: 'deptName'
      },
      deptList: [], // 侧边栏机构树
      // treeExpandData: [], // 默认展开机构树的第一级
      currentNodeKey: '', // 默认选择机构树的第一个节点
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '姓名',
          prop: 'userName',
          show: true,
          enableSlot: true
        },
        {
          label: '账号',
          prop: 'loginAccount',
          show: true
        },
        {
          label: '联系电话',
          prop: 'mobilePhone',
          show: true,
          enableSlot: true
        },
        {
          label: '机构',
          prop: 'deptName',
          show: true
        },
        {
          label: '邮箱',
          prop: 'userEmail',
          show: true,
          enableSlot: true
        },
        {
          label: '角色',
          prop: 'groupNameList',
          show: true,
          enableSlot: true
        },
        {
          label: '操作',
          prop: 'operators',
          minWidth: '120',
          fixed: 'right',
          align: 'center',
          children: {
            edit: {
              label: '编辑',
              color: '#267CDC',
              clickEvent: (thisVue, row) => {
                thisVue.$parent.userData = row
                thisVue.$parent.editUserDialogVisible = true
              },
              showFilter: (item, row, thisVue) => {
                return row.userId !== '1' && (!thisVue.$parent.$parent.isAdmin(row) || (thisVue.$parent.$parent.isAdmin(row) && thisVue.$parent.$parent.hasPermission()))
              }
            }
          }
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      },
      deptOperator: '', // 记录操作('新建'/'编辑')
      dialogVisible: false,
      dialogTitle: '新建机构',
      rules: {
        deptName: [{ required: true, message: '请输入机构名称', trigger: 'change' }],
        deptType: [{ required: true, message: '请选择机构类型', trigger: 'change' }],
        verifyType: [{ required: true, validator: checkVerifyType, trigger: 'change' }]
      },
      deptForm: {
        deptName: '',
        deptType: '',
        verifyType: '',
        detectItemIds: [],
        userId: '',
        parentId: '', // 新建机构的父节点ID
        company: '', // 公司名
        license: '', // 营业执照编号
        contactPerson: '', // 联系人姓名
        email: '', // 联系人邮箱
        phone: '' // 联系人电话
      },
      userList: [], // 部门领导
      nodeCache: {}, // 节点缓存
      addUserDialogVisible: false, // 添加成员弹窗
      editUserDialogVisible: false, // 编辑成员弹窗
      userData: [], // 编辑成员信息
      identifyList: [], // 鉴定项目
      departmentTypeList: [],
      identifyTypeList: []
    }
  },
  mounted() {
    this.getIdentifyTypes()
    this.getIdentifyItems()
    this.getDepartmentList()
    this.getUserList()
    this.getDepartmentTypes()
    // setTimeout(() => {
    //   this.deptList = [
    //     {
    //       deptId: '1',
    //       deptName: '三博会大沙发的发大发送到发送到防守打法说到底发送发送',
    //       children: [
    //         {
    //           deptId: '11',
    //           deptName: '分中心dfasdfsdd大发送到发送到发生的大法师的地方四大大法师的地方'
    //         },
    //         {
    //           deptId: '12',
    //           deptName: '鉴定机构'
    //         }
    //       ]
    //     }
    //   ]
    //   this.currentNodeKey = this.deptList[0].deptId
    //   this.treeExpandData.push(this.deptList[0].deptId)
    //   this.$nextTick(function() {
    //     this.$refs.treeRef.setCurrentKey(this.currentNodeKey)
    //   })
    // }, 1000)
  },
  methods: {
    // 获取机构列表
    async getDepartmentList(deptId = '1') {
      try {
        const { data } = await this.$api.department.getDepartmentList({
          deptId: '1'
        })
        if (data.code === 0) {
          this.deptList = []
          this.deptList.push(data.data)
          // this.treeExpandData.push(this.deptList[0].deptId)
          this.setCurrentKey(deptId)
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 获取当前机构成员列表
    async getDepartmentUserList(deptId) {
      this.listLoading = true
      try {
        const { data } = await this.$api.department.getDepartmentUserList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          deptId: deptId,
          nameOrAccount: this.search
        })
        if (data.code === 0) {
          this.listData = data.data.records
          this.$set(this.pageData, 'total', data.data.total)
          this.listData.forEach(item => {
            let groupDescArr = []
            item.groups.forEach(groupItem => {
              groupDescArr.push(groupItem.groupDescription ? groupItem.groupDescription : groupItem.groupName)
            })
            this.$set(item, 'groupNameList', groupDescArr)
          })
          console.log(this.listData)
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
        this.listLoading = false
      } catch (err) {
        this.listLoading = false
        throw new Error(err)
      }
    },
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.getDepartmentUserList(this.$refs.treeRef.getCurrentKey())
    },
    // 点击节点获取当前机构的成员列表
    handleNodeClick(node) {
      console.log('node click:')
      console.log(node)
      this.$set(this.pageData, 'currentPage', 1)
      this.getDepartmentUserList(node.deptId)
    },
    // 点击侧边栏功能按钮时传入当前点击的节点信息
    handleIconClick(data) {
      console.log(data)
      this.getDepartmentDetail(data.deptId)
      console.log(this.nodeCache)
      this.$set(this.deptForm, 'parentId', data.deptId)
    },
    // 获取机构详情
    async getDepartmentDetail(deptId) {
      try {
        this.detailLoading = true
        const { data } = await this.$api.department.getDepartmentDetail({ deptId: deptId })
        if (data.code === 0) {
          this.nodeCache = data.data
          this.detailLoading = false
        } else {
          this.detailLoading = false
          this.$message({
            type: 'error',
            message: data.message
          })
        }
        // if
      } catch (err) {
        this.detailLoading = false
        throw new Error(err)
      }
    },
    // 处理侧边栏点击操作
    handleOptionClick(command) {
      console.log(command)
      this.deptOperator = command
      switch (command) {
        case 'add':
          this.dialogTitle = '新建机构'
          this.$set(this.deptForm, 'deptId', '')
          this.$set(this.deptForm, 'deptName', '')
          this.$set(this.deptForm, 'deptType', '')
          this.$set(this.deptForm, 'userId', '')
          this.$set(this.deptForm, 'verifyType', '')
          this.$set(this.deptForm, 'company', '')
          this.$set(this.deptForm, 'license', '')
          this.$set(this.deptForm, 'contactPerson', '')
          this.$set(this.deptForm, 'email', '')
          this.$set(this.deptForm, 'phone', '')
          this.$set(this.deptForm, 'detectItemIds', [])
          this.dialogVisible = true
          break
        case 'edit':
          this.dialogTitle = '编辑机构'
          this.$set(this.deptForm, 'deptId', this.nodeCache.deptId)
          this.$set(this.deptForm, 'deptName', this.nodeCache.deptName)
          this.$set(this.deptForm, 'deptType', this.nodeCache.deptType)
          this.$set(this.deptForm, 'verifyType', this.nodeCache.verifyType)
          this.$set(this.deptForm, 'userId', this.nodeCache.userId)
          this.$set(this.deptForm, 'company', this.nodeCache.company)
          this.$set(this.deptForm, 'license', this.nodeCache.license)
          this.$set(this.deptForm, 'contactPerson', this.nodeCache.contactPerson)
          this.$set(this.deptForm, 'email', this.nodeCache.email)
          this.$set(this.deptForm, 'phone', this.nodeCache.phone)
          this.$set(this.deptForm, 'detectItemIds', this.nodeCache.detectItemIds)

          this.dialogVisible = true
          break
        case 'delete':
          this.deptDeleteCheck(this.nodeCache.deptId)
          break
        default:
          this.dialogTitle = ''
          break
      }
    },
    // 添加成员
    handleAdd() {
      this.addUserDialogVisible = true
    },
    handleUserDialogClose(flag) {
      if (flag === 'add') {
        this.addUserDialogVisible = false
      } else if (flag === 'edit') {
        this.editUserDialogVisible = false
      }
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getDepartmentUserList(this.$refs.treeRef.getCurrentKey())
    },
    // 获取领导人选择列表
    async getUserList() {
      try {
        const { data } = await this.$api.user.getUserList()
        if (data.code === 0) {
          this.userList = data.data
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 校验新建/编辑机构
    checkDeptForm() {
      this.$refs.deptForm.validate((valid) => {
        if (!valid) {
          return false
        } else {
          this.handleDeptSetting()
        }
      })
    },
    // 新增/编辑机构
    async handleDeptSetting() {
      try {
        if (this.deptOperator === 'add') {
          console.log(this.deptForm)
          const { data } = await this.$api.department.addDepartment(this.deptForm)
          if (data.code === 0) {
            this.$message({
              type: 'success',
              message: '添加成功'
            })
            this.dialogVisible = false
            this.getDepartmentList(data.data)
          } else {
            this.$message({
              type: 'error',
              message: data.message
            })
          }
        } else if (this.deptOperator === 'edit') {
          const { data } = await this.$api.department.editDepartment(this.deptForm)
          if (data.code === 0) {
            this.$message({
              type: 'success',
              message: '编辑成功'
            })
            this.dialogVisible = false
            this.getDepartmentList(this.$refs.treeRef.getCurrentKey())
          } else {
            this.$message({
              type: 'error',
              message: data.message
            })
          }
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 删除机构
    async deptDeleteCheck(deptId) {
      this.$confirm('此操作将永久删除该机构，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleDeptDelete(deptId)
      }).catch(() => {})
    },
    async handleDeptDelete(deptId) {
      try {
        const { data } = await this.$api.department.deleteDepartment({ deptId: deptId })
        console.log(data)
        if (data.code === 0) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          // 若删除机构为当前选择机构，则选择重置
          if (this.$refs.treeRef.getCurrentKey() === deptId) {
            this.getDepartmentList()
          } else {
            this.getDepartmentList(this.$refs.treeRef.getCurrentKey())
          }
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 设置当前选择机构
    setCurrentKey(deptId) {
      this.currentNodeKey = deptId
      this.$nextTick(function() {
        this.$refs.treeRef.setCurrentKey(this.currentNodeKey)
      })
      this.getDepartmentUserList(deptId)
    },
    // 编辑用户信息成功
    handleEditSuccess() {
      this.handlePageChange(1)
    },
    // 获取部门类型
    async getDepartmentTypes() {
      try {
        const { data } = await this.$api.department.getDepartmentTypes()
        if (data.code === 0) {
          this.departmentTypeList = data.data
          console.log(data)
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 获取鉴定类型列表
    async getIdentifyTypes() {
      try {
        const { data } = await this.$api.department.getIdentifyTypes()
        if (data.code === 0) {
          console.log(data)
          this.identifyTypeList = data.data
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 获取终鉴鉴定项目列表
    async getIdentifyItems() {
      try {
        const { data } = await this.$api.department.getIdentifyItems()
        if (data.code === 0) {
          this.handleIdentifyItems(data.data)
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    // 处理鉴定项目
    handleIdentifyItems(data) {
      let category = []
      data.forEach(item => {
        const res = category.find(type => type.detectMethod === item.detectMethod)
        if (!res) {
          category.push({
            detectMethod: item.detectMethod,
            options: [{
              detectItemName: item.detectItemName,
              detectItemId: item.detectItemId
            }]
          })
        } else {
          res.options.push({
            detectItemName: item.detectItemName,
            detectItemId: item.detectItemId
          })
        }
      })
      this.identifyList = category
    },
    handleDeptTypeChange() {
      this.$set(this.deptForm, 'verifyType', '')
    },
    hasPermission() {
      const userInfo = JSON.parse(getUserInfo())
      return userInfo.permissionsTags.includes('admin')
    },
    isAdmin(info) {
      return info.groupIds.includes('1')
    }
  }
}
</script>

<style lang="scss">
.dropdown-delete {
  color: $--color-danger;
  &:focus, &:hover {
    color: $--color-danger !important;
  }
}
.identify-type-popper {
  .el-select-group__title {
    color: $--color-text-secondary;
  }
}
.select-dept-type, .select-leader {
  width: 250px;
}
.dept-dropdown {
  .el-dropdown-item {
    .el-dropdown-menu__item:not(.is-disabled):hover, .el-dropdown-menu__item:focus {
      color: #3370FF;
      background-color: #F0F4FF;
    }
  }
}
</style>
<style lang="scss" scoped>
.department {
  .main {
    display: flex;
    .side-bar {
      width: 264px;
      padding-right: 16px;
      border-right: 1px solid $--border-color-base;
      .search-bar {
        margin-bottom: 16px;
      }
      .dept-tree {
        ::v-deep.el-tree {
          user-select: none;
          .el-tree-node.is-current  {
            & > .el-tree-node__content {
              color: #267cdc;
              background-color: #e9f2fc;
              .dept-tree-node {
                &-icon {
                  color: #267cdc;
                  &:hover {
                    background-color: #E1EAFF;
                  }
                }
              }
            }
          }
          .el-tree-node__content{
            height: 40px;
            font-size: 14px;
            .dept-tree-node {
              flex: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;
              overflow: hidden;
              .dept-tree-node-icon {
                display: flex;
                width: 18px;
                height: 18px;
                text-align: center;
                margin-right: 4px;
                i {
                  width: 18px;
                  font-size: 16px;
                  transform: rotate(90deg);
                }
                &:hover {
                  background-color: rgba(#1F2329, .1);
                }
              }
            }
          }
        }
      }
    }
    .content {
      width: calc(100% - 264px);
      padding-left: 16px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;
        margin-bottom: 16px;
        border-bottom: 1px solid $--border-color-base;
        .left {
          color: $--color-text-secondary;
          .count {
            margin-left: 8px;
          }
        }
        .right {

        }
      }
    }
  }
  ::v-deep.identify-type-select {
    width: 250px;
    .el-select__tags {
      .el-select__tags-text {
        color: $--color-text-primary;
      }
      .el-tag__close {
        background-color: #f0f2f5;
        color: #a3a3a3;
      }
    }
  }
}

</style>
