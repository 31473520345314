<template>
  <div class="law-summary">
    <div class="ym-panel-radius">
      <div class="title">
        <span>法律档案</span>
      </div>
      <template>
        <div class="dept">
          <div class="dept-header">{{dataInfo.identifyLegals && dataInfo.identifyLegals[0].deptName}}</div>
          <div class="dept-result">
            <div class="block-left">
              <el-row class="row-item">
                <el-col>
                  <div class="label">法律意见：</div>
                  <div class="content">
                    <template v-if="dataInfo.identifyLegals && !dataInfo.identifyLegals[0].file">
                      <span class="tips-warning">(待提交)</span>
                      <el-button type="primary" size="small" @click="goSubmit('law')" v-if="hasPermission || (userInfo && (userInfo.deptId === dataInfo.identifyLegals[0].deptId))">去提交</el-button>
                    </template>
                    <template v-else>
                      <p v-html="showParagraph(dataInfo.identifyLegals ? dataInfo.identifyLegals[0].opinion : '')"></p>
                    </template>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="block-right">
              <div class="operator">
                <el-button class="btn" :disabled="dataInfo.identifyLegals && !dataInfo.identifyLegals[0].file" @click="viewReport">法律报告</el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { showParagraph } from '@/utils/handleParagraph.js'

export default {
  props: {
    submitted: {
      type: Boolean,
      default: false
    },
    dataInfo: {
      type: Object,
      default: () => {}
    },
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    hasPermission() {
      return this.userInfo.permissionsTags.includes('admin')
    }
  },
  methods: {
    showParagraph,
    goSubmit(flag) {
      this.$emit('goSubmit', flag)
    },
    viewReport() {
      const url = this.FileUrl + this.dataInfo.identifyLegals[0].file
      window.open(url, 'blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.law-summary {
  color: $--color-text-primary;
  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 2px 0 24px;
    span {
      margin-right: 8px;
      font-weight: 700;
    }
  }
  .dept {
    border: 1px solid #EBEDF0;
    & + .dept {
      margin-top: 24px;
    }
    .dept-header {
      height: 56px;
      line-height: 56px;
      padding: 0 25px;
      font-size: 16px;
      font-weight: 600;
      background-color: #F7F8FA;
    }
    .dept-result {
      display: flex;
      padding: 20px 0px;
      .block-left {
        width: 81.5%;
        padding: 0 25px;
        .row-item {
          font-size: 14px;
          margin-bottom: 12px;
          .el-col {
            display: flex;
            align-items: center;
          }
          .label {
            align-self: flex-start;
            width: 70px;
            min-width: 70px;
            color: $--color-text-secondary;
            &.owner {
              min-width: 90px;
            }
          }
          .content {
            .el-button {
              width: 60px;
              height: 24px;
              padding: 0;
              margin-left: 12px;
              text-align: center;
              line-height: 24px;
            }
            .tips-warning {
              color: $--color-warning;
            }
          }
        }
      }
      .block-right {
        display: flex;
        width: calc(100% - 81.5%);
        justify-content: center;
        align-items: center;
        border-left: 1px solid $--border-color-base;
        min-width: 280px;
        .operator {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .btn {
            margin: 0 auto;
            width: 130px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            padding: 0 6px;
            font-size: 14px;
            span {
              display: inline-block;
              width: 100%;
            }
            & + .btn {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
