<template>
  <div class="record-list">
       <!-- 搜索栏 -->
      <div class="filter">
       <el-tabs v-model="selectedStatus" type="card">
        <!-- 档案信息 -->
        <el-tab-pane label="待指派" name="待指派" v-if="hasPermission('leader') || hasPermission('operator') || hasPermission('admin')"></el-tab-pane>
        <el-tab-pane label="待初鉴" name="待初鉴"></el-tab-pane>
        <el-tab-pane label="已初鉴" name="已初鉴"></el-tab-pane>
      </el-tabs>
         <!-- <el-radio-group v-model="selectedStatus">
          <el-radio-button label="待审核"></el-radio-button>
          <el-radio-button label="已审核"></el-radio-button>
        </el-radio-group> -->
        <div class="block">
          <div class="top-left">
            <div class="selector">
              <el-select v-model="selectedCenter"
                        placeholder="分中心"
                        clearable
                        filterable
                        @change="handleSearch">
                <el-option v-for="center in centerList"
                          :key="center.deptId"
                          :label="center.deptName"
                          :value="center.deptId">
                </el-option>
              </el-select>
            </div>
            <div class="selector">
              <el-select v-model="selectedType"
                        placeholder="分类"
                        clearable
                        filterable
                        @change="handleSearch">
                <el-option v-for="type in typeList"
                          :key="type.value"
                          :label="type.value"
                          :value="type.value">
                </el-option>
              </el-select>
            </div>

          </div>

          <div class="search-bar top-right">
            <el-input v-model="search"
                      class="search-input"
                      placeholder="请输入档案名称或者ID"
                      prefix-icon="el-icon-search"
                      clearable
                      @clear="handleSearch"
                      @keyup.enter.native="handleSearch">
                      <el-button slot="append" @click="handleSearch"
                        type="primary">搜索</el-button>
            </el-input>
            <div class="button-box">
              <el-button @click="resetSearchForm" plain>重置</el-button>
            <el-button style="margin-left:40px" icon="el-icon-sort" type="text" @click="sort()">{{ascend?'倒序':'正序'}}</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 主体内容 -->
      <div class="ym-panel-radius clearfix">
        <!-- 卡片展示 START  -->
        <!-- <div class="ym-head">
          <div class="flex-left">全部{{pageData.total}}藏品 已加载{{data.length}}藏品</div>
        </div>
        <card-item :data="data" @toDetails="toDetails" v-if="data && data.length > 0"></card-item>
        <YmPagination v-if="data && data.length > 0" :pageData="pageData" @handlePageChange="handlePageChange"></YmPagination> -->
        <!-- 卡片展示 END  -->
        <!-- 列表展示 START -->
        <!-- <div v-if="data && data.length > 0"> -->
        <div>
          <ym-table-list :loading="loading"
                        :data="data"
                        :columns="listColumns"
                        :pageData="pageData"
                        class="table-list"
                        @handlePageChange="handlePageChange">
            <!-- 藏品正视图 -->
            <template #main="{row}">
              <div @click="toDetails(row)" class="table-list-img">
                <div class="img">
                  <el-image :src="FileUrl + row.frontThumbnail" fit="contain" lazy>
                    <img class="alt-img" slot="placeholder" src="./img/logo-header.png" alt="">
                    <img class="alt-img" slot="error" src="./img/logo-header.png" alt="">
                  </el-image>
                </div>
              </div>
            </template>
            <!-- 藏品名称 -->
            <template #name={row}>
              <div @click="toDetails(row)" class="table-list-name">
                <div class="name">
                  <div class="ellipsis" :title="row.name">{{row.name}}</div>
                  <div class="ellipsis" :title="row.archiveId">{{`ID:${row.archiveId}`}}</div>
                </div>
              </div>
            </template>
            <!-- 派单类型 -->
            <template #assignType="{row}">
              {{row.identifyType}}
            </template>
            <!-- 派单时间 -->
            <template #assignTime="{row}">
              {{row.dispatchTime | timeFilter('YYYY-MM-DD HH:mm:ss')}}
            </template>
            <template #codeStatus="{row}">
              {{row.codeStatus || '-'}}
            </template>
            <!-- 初鉴时间 -->
            <template #identifyTime="{row}">
              {{row.identifyTime | timeFilter('YYYY-MM-DD HH:mm:ss')}}
            </template>
            <!-- 初鉴结果 -->
            <template #result="{row}">
              <template v-if="row.identifyResult === '通过'">通过</template>
              <template v-else-if="row.identifyResult === '不通过（现代艺术品）'">
                <div>艺术品</div>
                <div>(不通过)</div>
              </template>
            </template>
            <!-- 初鉴专家 -->
            <template #expert="{row}">
              <div v-if="row.userName">
                <div>{{row.loginAccount}}</div>
                <div>{{`(${row.userName})`}}</div>
              </div>
              <div v-else>{{`${row.loginAccount}`}}</div>
            </template>
            <template #desc="{row}">
              <template v-if="row.desc">
                <el-tooltip class="item" effect="dark" :content="row.desc" placement="top">
                  <text-line :text="row.desc" :clamp="3"></text-line>
                </el-tooltip>
              </template>
              <template v-else>--</template>
            </template>
          </ym-table-list>
        </div>
        <!-- 列表展示 END -->
        <!-- <no-record v-if="!data || data.length===0">暂无 {{selectedStatus}} 藏品，可在 筛选栏 切换 {{selectedStatus==='待初鉴'?'已初鉴':'待初鉴'}}  藏品查看</no-record> -->
        <el-dialog v-if="dialogVisible"
                   :visible.sync="dialogVisible"
                   title="指派藏品初鉴专家"
                   top="250px"
                   width="500px">
          <el-form ref="assignForm"
                   :model="assignForm"
                   :rules="rules"
                   label-width="90px">
            <el-row :gutter="16">
              <el-col :span="22">
                <el-form-item prop="name" label="指派藏品">
                  <el-input v-model="assignForm.name" disabled :title="assignForm.name" class="name-ellipsis"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="16">
              <el-col :span="22">
                <el-form-item prop="expert" label="初鉴专家">
                  <el-select v-model="assignForm.expert" placeholder="请选择" filterable>
                    <el-option v-for="expert in expertList"
                              :key="expert.userId"
                              :label="expert.userName ? `${expert.userName}(${expert.loginAccount})` : expert.loginAccount"
                              :value="expert.userId"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitAssign" :loading="assignLoading">确 定</el-button>
          </span>
        </el-dialog>
      </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
// import YmPagination from '@/components/TableList/YmPagination'
import { assignColumns, firstIdentifyColumns, finishIdentifyColumns } from './tableColumns.js'
import { getUserInfo } from '@/utils/localStorage.js'
import store from '@/store'

export default {
  components: {
    // YmPagination,
    YmTableList
  },
  data() {
    return {
      assignLoading: false,
      userInfo: {},
      permissionsTags: [],
      loading: false,
      dialogVisible: false,
      tableSearch: '',
      search: '',
      selectedCenter: '',
      selectedType: '',
      selectedStatus: '',
      ascend: false,
      centerList: [],
      typeList: [
        { value: '陶瓷' },
        { value: '玉器玉雕' },
        { value: '古币纸钱' },
        { value: '铜器铜雕' },
        { value: '中国书画' },
        { value: '古典家具' },
        { value: '金银珠宝' },
        { value: '雕品工艺' }
      ],
      data: [],
      listColumns: [

      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      },
      expertList: [
        {
          id: 1,
          label: '专家A'
        },
        {
          id: 2,
          label: '专家B'
        }
      ],
      assignForm: {
        archiveId: '',
        name: '',
        expert: ''
      },
      rules: {
        expert: [
          { required: true, message: '请选择初鉴专家', trigger: 'change' }
        ]
      },
      listName: 'first-identify-list',
      useListInfo: false // 是否使用Vuex中的列表页码和搜索信息
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/identify/first-identify/details')) {
      next(vm => { vm.useListInfo = true })
    }
    next()
  },
  mounted() {
    this.userInfo = JSON.parse(getUserInfo())
    this.permissionsTags = this.userInfo && this.userInfo.permissionsTags
    console.log(this.userInfo)
    this.getAllDepartmentList('分中心', this.centerList)
    console.log(this.useListInfo)
    console.log(this.isStatusExist())
    if (this.useListInfo && this.isStatusExist()) {
      this.initListInfo(this.listName)
    } else {
      if (this.hasPermission('leader') || this.hasPermission('operator') || this.hasPermission('admin')) {
        this.selectedStatus = '待指派'
      } else {
        this.selectedStatus = '待初鉴'
      }
    }
    this.getTableColumns()
    // this.getList()
  },
  watch: {
    selectedStatus() {
      if (!this.useListInfo) {
        this.data = []
        this.$set(this.pageData, 'currentPage', 1)
        this.getTableColumns()
        this.resetSearchForm()
      } else {
        this.useListInfo = false
        this.getList()
      }
    }
  },
  computed: {
  },
  methods: {
    sort() {
      this.ascend = !this.ascend
      this.setListInfo()
      this.getList()
    },
    toDetails(items) {
      this.setListInfo()
      this.$router.push({
        name: 'identifyDetails',
        query: { archiveId: items.archiveId }
      })
    },
    // 新窗口打开
    toDetailsBlank(items) {
      const routeData = this.$router.resolve({
        name: 'identifyDetails',
        query: { archiveId: items.archiveId }
      })
      window.open(routeData.href, '_blank')
    },
    // 重置表单
    resetSearchForm() {
      this.search = ''
      this.selectedCenter = ''
      this.selectedType = ''
      this.ascend = false
      this.handleSearch()
    },
    // 搜索栏搜索
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.setListInfo()
      this.getList()
    },
    // 分页跳转
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      this.setListInfo()
      this.getList()
    },
    // 获取藏品列表
    async getList() {
      this.loading = true
      try {
        let res = {}
        const params = {
          page: this.pageData.currentPage,
          size: this.pageData.size,
          status: this.selectedStatus,
          category: this.selectedType,
          nameOrId: this.search,
          ascend: this.ascend,
          subCenter: this.selectedCenter
        }
        res = await this.$api.record.getListExpert(params)

        const { data } = res
        if (data.code === 0) {
          this.data = data.data.records
          this.pageData.total = data.data.total
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
        this.loading = false
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
    },
    // 指派
    submitAssign() {
      this.$refs.assignForm.validate((valid) => {
        if (valid) {
          this.setExpert()
        } else {
          return false
        }
      })
    },
    // 指派鉴定专家
    async setExpert() {
      try {
        this.assignLoading = true
        const { data } = await this.$api.identify.setExpert({
          archiveId: this.assignForm.archiveId,
          userId: this.assignForm.expert
        })
        if (data.code === 0) {
          this.assignLoading = false
          this.$message({
            type: 'success',
            message: '指派成功'
          })
          this.dialogVisible = false
          this.handleSearch()
        } else {
          this.assignLoading = false
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.assignLoading = false
        throw new Error(err)
      }
    },
    // 根据不同状态指定列表展示的列属性
    getTableColumns() {
      switch (this.selectedStatus) {
        case '待指派':
          this.listColumns = assignColumns
          break
        case '待初鉴':
          this.listColumns = firstIdentifyColumns
          break
        case '已初鉴':
          this.listColumns = finishIdentifyColumns
          break
        default:
          this.listColumns = finishIdentifyColumns
          break
      }
    },
    // 权限查询
    hasPermission(tag) {
      return this.permissionsTags.includes(tag)
    },
    // 查询当前用户是否为该订单跟进人
    checkAssign(row) {
      return this.userInfo.userLoginAccount === row.loginAccount
    },
    // 若角色为运营，获取当前用户所属分中心能查看的机构；若为鉴定专家，则获取所有分中心机构
    async getAllDepartmentList(deptType, list) {
      try {
        let res = {}
        if (this.hasPermission('operator')) {
          res = await this.$api.record.getDepartmentListByType({ deptType: deptType })
        } else {
          res = await this.$api.department.getAllDepartmentList({ deptType: deptType })
        }
        const { data } = res
        if (data.code === 0) {
          if (deptType === '分中心') {
            this.centerList = data.data
          } else if (deptType === '鉴定机构') {
            this.organizationList = data.data
          }
          console.log(list)
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 获取当前藏品可指派的专家列表
    async getExpertList() {
      try {
        const { data } = await this.$api.identify.getExpertList({
          archiveId: this.assignForm.archiveId
        })
        if (data.code === 0) {
          this.expertList = data.data
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 若 Vuex 中保存有当前列表的页码及搜索信息，则获取所有信息并赋值
    initListInfo(listName) {
      const listInfo = store.getters.listInfo
      const index = store.getters.listInfo.findIndex(item => item.listName === listName)
      console.log(listInfo[index])
      if (index !== -1) {
        const list = listInfo[index]
        this.$set(this.pageData, 'currentPage', list.pageData.currentPage)
        this.search = list.search.search
        this.selectedStatus = list.search.selectedStatus
        this.selectedType = list.search.selectedType
        this.selectedCenter = list.search.selectedCenter
        this.ascend = list.search.ascend
      }
    },
    // 将当前页面信息保存至Vuex中
    setListInfo() {
      const listInfo = {
        listName: this.listName,
        pageData: this.pageData,
        search: {
          search: this.search,
          selectedStatus: this.selectedStatus,
          selectedType: this.selectedType,
          selectedCenter: this.selectedCenter,
          ascend: this.ascend
        }
      }
      this.$store.dispatch('setListInfo', listInfo)
    },
    // 判断Vuex中的selectedStatus是否仍存在(有效)
    isStatusExist() {
      const listInfo = store.getters.listInfo
      const index = store.getters.listInfo.findIndex(item => item.listName === this.listName)
      if (index !== -1 && listInfo[index].search && listInfo[index].search.selectedStatus) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$img-width: 290px;
.record-list {
  .filter{
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 20px 24px;
    margin-bottom: 20px;
  }
  ::v-deep .filter{
    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
      color: #fff;
      background: $--color-primary;
    }
  }
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 20px 24px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .top-left {
      display: flex;
      margin-right: 8px;
      .selector + .selector {
        margin-left: 8px;
      }
    }
  }
  .button-box{
    margin-left: 10px;
    min-width: 160px;
  }
  .search-bar {
    display: flex;
    ::v-deep .search-input {
      width: 350px;
    }
  }
  ::v-deep .el-input-group__append{
    background-color: $--color-primary;
    color: #fff;
  }
  .ym-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    height: 40px;
    .flex-left {
      align-self: flex-end;
    }
  }
  .table-list {
    // ::v-deep .el-button + span {
    //   margin-left: 20px;
    // }
    &-img {
      cursor: pointer;
      .img {
        ::v-deep.el-image {
          width: ($img-width * 0.6);
          img {
            width: 100%;
          }
          .alt-img{
            width: 100%;
            filter: grayscale(0.6);
            opacity: .5;
          }
        }
      }
    }
    &-name {
      cursor: pointer;
      .name:hover {
        div {
          color: $--color-primary;
        }
      }
    }
  }
  .name-ellipsis {
    ::v-deep.el-input__inner {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
